<template>
  <div>
    <a-modal
      width="80%"
      v-model="visible"
      title="会议室申请"
      :okButtonProps="{ style: { display: 'none' } }"
      cancelText="关闭"
    >
      <div class="form-content">
        <a-form-model
          ref="ruleForm"
          :model="form"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          hideRequiredMark
        >
          <a-form-model-item label="会议室名称" prop="useDate">
            <a-input
              placeholder="请输入会议室名称"
              v-model="roomData.name"
              disabled
            />
          </a-form-model-item>

          <a-form-model-item label="日期" prop="useDate">
            <a-date-picker
              placeholder="请选择会议日期"
              format="YYYY-MM-DD"
              v-model="form.useDate"
              disabled
            />
          </a-form-model-item>

          <a-form-model-item label="时间段">
            <div class="flex-wrap flex-wrap-wrap">
              <div
                :class="['item', itemIndex.indexOf(index) > -1 ? 'active' : '']"
                class="item"
                v-for="(item, index) in dataTime"
                :key="index"
              >
                {{ item.time }}
              </div>
            </div>
          </a-form-model-item>

          <a-form-model-item label="会议人数" prop="num">
            <a-input-number
              :min="1"
              :step="1"
              v-model="form.num"
              placeholder="请输入"
              style="width: 100%"
              disabled
            />
          </a-form-model-item>

          <a-form-model-item label="备注">
            <a-textarea
              v-model="form.remark"
              placeholder="请输入备注"
              :rows="4"
              disabled
            />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { getHistoryFormDataByProcessInstanceId } from '@/api/process'
import { yqmsMeetRoomQueryById } from '@/api/api'
export default {
  name: 'ConferenceRoomModal',
  data() {
    return {
      labelCol: { span: 3 },
      wrapperCol: { span: 14 },
      form: {
        useDate: undefined,
        remark: '',
        num: undefined,
      },
      dataTime: [
        {
          key: 0,
          time: '08:00-08:30',
        },
        {
          key: 1,
          time: '08:30-09:00',
        },
        {
          key: 2,
          time: '09:00-09:30',
        },
        {
          key: 3,
          time: '10:00-10:30',
        },
        {
          key: 4,
          time: '10:30-11:00',
        },
        {
          key: 5,
          time: '11:00-11:30',
        },
        {
          key: 6,
          time: '11:30-12:00',
        },
        {
          key: 7,
          time: '12:00-12:30',
        },
        {
          key: 8,
          time: '12:30-13:00',
        },
        {
          key: 9,
          time: '13:00-13:30',
        },
        {
          key: 10,
          time: '13:30-14:00',
        },
        {
          key: 11,
          time: '14:00-14:30',
        },
        {
          key: 12,
          time: '14:30-15:00',
        },
        {
          key: 13,
          time: '15:00-15:30',
        },
        {
          key: 14,
          time: '15:30-16:00',
        },
        {
          key: 15,
          time: '16:00-16:30',
        },
        {
          key: 16,
          time: '16:30-17:00',
        },
        {
          key: 17,
          time: '17:00-17:30',
        },
        {
          key: 18,
          time: '17:30-18:00',
        },
      ],
      itemIndex: [],
      applyData: {},
      roomData: {},
      visible: false,
    }
  },
  methods: {
    showModal(record) {
      this.getInfo(record.processInstanceId)
      this.visible = true
    },
    moment,
    getInfo(id) {
      const params = {
        processInstanceId: id,
      }
      getHistoryFormDataByProcessInstanceId(params).then((res) => {
        if (res.success) {
          this.applyData = JSON.parse(res.result.formData)
          console.log('会议室', this.applyData)
          this.setRoomInfo(this.applyData.room_id)
          this.form.useDate = moment(this.applyData.use_date).format()
          this.setTimes()
          this.form.remark = this.applyData.remark
          this.form.num = this.applyData.num
        } else {
          this.$message.warning(res.message)
        }
      })
    },

    // 获取会议室信息
    setRoomInfo(id) {
      const params = {
        id: id,
      }

      yqmsMeetRoomQueryById(params)
        .then((res) => {
          if (res.success) {
            // console.log(res)
            this.roomData = res.result
          } else {
            this.$message.error(res.message)
          }
        })
        .catch((err) => {
          this.$message.error(err.message)
        })
    },

    setTimes() {
      this.itemIndex = []
      const timeList = this.applyData.used_times.split(',')
      const selectedList = this.dataTime.filter((obj) =>
        timeList.some((obj1) => obj1 == obj.time)
      )
      selectedList.forEach((item) => {
        this.itemIndex.push(item.key)
      })
    },
  },
}
</script>
<style lang="less" scoped>
.item {
  width: 106px;
  border: 1px solid #ddd;
  padding: 0 10px;
  cursor: pointer;
  margin: 5px;
  text-align: center;

  &.active {
    border-color: #1890ff;
    background: #1890ff;
    color: #fff;
  }

  &.time-disable {
    border-color: #ff4d4f;
    background: #ff4d4f;
    color: #fff;
  }
}
</style>
