<template>
  <div>
    <div :class="['card', authMessage != 101 ? 'card-height' : '']">
      <a-form-model
        ref="ruleForm"
        :model="userData"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        hideRequiredMark
      >
        <a-row class="info-box">
          <a-col :span="12">
            <a-form-model-item label="登录账号">
              <a-input
                class="ant-input-r"
                disabled
                v-model="userData.username"
                placeholder="请输入登录账号"
                :maxLength="30"
                read-only
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="姓名">
              <a-input
                v-model="userData.realname"
                placeholder="请输入姓名"
                :maxLength="30"
                :read-only="authMessage != 101 && authMessage != 201"
              />
            </a-form-model-item>
          </a-col>

          <a-col :span="12">
            <a-form-model-item label="手机号">
              <a-input
                disabled
                v-model="userData.phone"
                placeholder="请输入手机号"
                :maxLength="11"
                read-only
              />
            </a-form-model-item>
          </a-col>

          <a-col :span="12">
            <a-form-model-item label="邮箱">
              <a-input
                v-model="userData.email"
                placeholder="请输入邮箱"
                :maxLength="30"
                :read-only="authMessage != 101 && authMessage != 201"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <div class="enterprise" v-if="authMessage === 101">
      <div class="title flex-wrap flex-h-center flex-between">
        <div class="horn-title-style">企业信息</div>
        <a-button
          class="btn-style"
          type="primary"
          @click="submitEnterpriseInfo"
          :loading="Eloading"
          >保存</a-button
        >
      </div>
      <div class="info">
        <a-form-model
          ref="enterprisForm"
          :model="enterpriseData"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :rules="rules"
        >
          <a-row class="info-box">
            <a-col :span="12">
              <a-form-model-item label="企业电话" prop="phone">
                <a-input
                  v-model="enterpriseData.phone"
                  placeholder="请输入企业电话"
                  :maxLength="11"
                  :read-only="authMessage != 101 && authMessage != 201"
                />
              </a-form-model-item>
            </a-col>

            <a-col :span="12">
              <a-form-model-item label="法人电话" prop="representativePhone">
                <a-input
                  v-model="enterpriseData.representativePhone"
                  placeholder="请输入法人电话"
                  :maxLength="11"
                  :read-only="authMessage != 101 && authMessage != 201"
                />
              </a-form-model-item>
            </a-col>

            <a-col :span="12">
              <a-form-model-item label="安全员名称" prop="safetyOfficerName">
                <a-input
                  v-model="enterpriseData.safetyOfficerName"
                  placeholder="请输入安全员名称"
                  :maxLength="30"
                  :read-only="authMessage != 101 && authMessage != 201"
                />
              </a-form-model-item>
            </a-col>

            <a-col :span="12">
              <a-form-model-item label="安全员电话" prop="safetyOfficerPhone">
                <a-input
                  v-model="enterpriseData.safetyOfficerPhone"
                  placeholder="请输入安全员电话"
                  :maxLength="11"
                  :read-only="authMessage != 101 && authMessage != 201"
                />
              </a-form-model-item>
            </a-col>

            <a-col :span="12">
              <a-form-model-item label="企业邮箱" prop="email">
                <a-input
                  v-model="enterpriseData.email"
                  placeholder="请输入企业邮箱"
                  :maxLength="60"
                  :read-only="authMessage != 101 && authMessage != 201"
                />
              </a-form-model-item>
            </a-col>

            <a-col :span="12">
              <a-form-model-item label="所属行业">
                <j-dict-select-tag
                  v-model="enterpriseData.trade"
                  dictCode="enterprise_trade"
                  placeholder="请选择所属行业"
                  :disabled="authMessage != 101 && authMessage != 201"
                />
              </a-form-model-item>
            </a-col>

            <a-col :span="12">
              <a-form-model-item label="企业规模">
                <j-dict-select-tag
                  dictCode="enterprise_staff_count"
                  placeholder="请选择企业规模"
                  v-model="enterpriseData.staffCount"
                  :disabled="authMessage != 101 && authMessage != 201"
                />
              </a-form-model-item>
            </a-col>

            <a-col :span="12">
              <a-form-model-item label="融资状态">
                <j-dict-select-tag
                  dictCode="enterprise_financing_status"
                  placeholder="请选择融资状态"
                  v-model="enterpriseData.financingStatus"
                  :disabled="authMessage != 101 && authMessage != 201"
                />
              </a-form-model-item>
            </a-col>

            <a-row>
              <a-col :span="12">
                <a-form-model-item label="企业福利">
                  <j-multi-select-tag
                    dictCode="enterprise_welfare"
                    placeholder="请选择企业福利"
                    v-model="enterpriseData.welfare"
                    :disabled="authMessage != 101 && authMessage != 201"
                  />
                </a-form-model-item>
              </a-col>

              <a-col :span="12">
                <a-form-model-item label="企业类型">
                  <j-multi-select-tag
                    dictCode="enterprise_type"
                    placeholder="请选择企业类型"
                    v-model="enterpriseData.type"
                    :disabled="authMessage != 101 && authMessage != 201"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-col :span="12">
              <a-form-model-item label="企业描述" prop="discription">
                <a-textarea
                  placeholder="请输入企业描述"
                  :rows="10"
                  :allowClear="true"
                  :showCount="true"
                  :maxLength="500"
                  v-model="enterpriseData.discription"
                  :read-only="authMessage != 101 && authMessage != 201"
                />
              </a-form-model-item>
            </a-col>

            <a-col :span="12">
              <a-form-model-item label="企业地址" prop="address">
                <a-textarea
                  placeholder="请输入企业地址"
                  :rows="10"
                  :maxLength="120"
                  :allowClear="true"
                  :showCount="true"
                  v-model="enterpriseData.address"
                  :read-only="authMessage != 101 && authMessage != 201"
                />
              </a-form-model-item>
            </a-col>

            <a-col :span="12">
              <a-form-model-item label="企业照片" prop="enterprisePic">
                <image-upload
                  class="avatar-uploader"
                  text="上传企业照片"
                  imgHeight="120px"
                  :bizPath="bizPath"
                  v-model="enterpriseData.enterprisePic"
                  :disabled="authMessage != 101 && authMessage != 201"
                ></image-upload>
                <div class="e-pic-tips">用于平台展示</div>
              </a-form-model-item>
            </a-col>

            <a-col :span="12">
              <a-form-model-item label="企业Logo" prop="enterpriseLogo">
                <image-upload
                  class="avatar-uploader"
                  text="上传企业Logo"
                  imgHeight="120px"
                  :bizPath="bizPath"
                  v-model="enterpriseData.enterpriseLogo"
                  :disabled="authMessage != 101 && authMessage != 201"
                ></image-upload>
              </a-form-model-item>
            </a-col>

            <a-col :span="24">
              <a-form-model-item
                label="企业办公照片"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 20 }"
                prop="enterpriseOfficePic"
              >
                <many-img-upload
                  class="avatar-uploader"
                  text="上传"
                  :bizPath="bizPath"
                  v-model="enterpriseData.enterpriseOfficePic"
                  :isMultiple="true"
                  :disabled="authMessage != 101 && authMessage != 201"
                ></many-img-upload>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </div>
    <div class="footer" v-if="authMessage === 0">
      <a-button
        class="btn-style"
        type="primary"
        v-if="authMessage === 0"
        @click="$router.push({ path: '/authentication' })"
      >
        认证
      </a-button>
      <!-- <a-button class="btn-style edit-btn" v-if="!btnState" @click="editInfo"
        >编辑</a-button
      >
      <a-button
        class="btn-style"
        v-if="btnState"
        @click="submit"
        :loading="loading"
        >保存</a-button
      > -->
    </div>
  </div>
</template>
<script>
import reg from '@/api/regular'
import { mapState, mapGetters, mapActions } from 'vuex'
import {
  editUserInfo,
  userQueryById,
  yqmsEnterpriseEdit,
  yqmsEnterpriseInfo,
} from '@/api/api'
import JDictSelectTag from '@/components/dict/JDictSelectTag'
import ImageUpload from '@/components/Assembly/ImageUpload'
import JMultiSelectTag from '@/components/dict/JMultiSelectTag'
import ManyImgUpload from '@/components/Assembly/ManyImgUpload'
export default {
  components: {
    JDictSelectTag,
    ImageUpload,
    JMultiSelectTag,
    ManyImgUpload,
  },
  data() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      userData: {
        username: '',
        realname: '',
        phone: '',
        email: '',
      },
      rules: {
        phone: [
          {
            required: true,
            message: '请输入企业电话',
            trigger: ['change', 'blur'],
          },
        ],
        representativePhone: [
          {
            required: true,
            message: '请输入法人电话',
            trigger: ['change', 'blur'],
          },
        ],
        safetyOfficerName: [
          {
            required: true,
            message: '请输入安全员名称',
            trigger: ['change', 'blur'],
          },
        ],
        safetyOfficerPhone: [
          {
            required: true,
            message: '请输入安全员电话',
            trigger: ['change', 'blur'],
          },
        ],
        email: [
          {
            required: true,
            message: '请输入企业邮箱',
            trigger: ['change', 'blur'],
          },
        ],
        discription: [
          {
            required: true,
            message: '请输入企业描述',
            trigger: ['change', 'blur'],
          },
        ],
        enterprisePic: [
          {
            required: true,
            message: '请上传企业照片',
            trigger: 'change',
          },
        ],
        address: [
          {
            required: true,
            message: '请输入企业地址',
            trigger: ['change', 'blur'],
          },
        ],
        enterpriseLogo: [
          {
            required: true,
            message: '请上传企业Logo',
            trigger: 'change',
          },
        ],
        enterpriseOfficePic: [
          {
            required: true,
            message: '请上传企业办公照片',
            trigger: 'change',
          },
        ],
      },
      btnState: false,
      readonlyState: true,
      disabledState: true,
      loading: false,
      Eloading: false,
      enterpriseData: {
        phone: '',
        email: '',
        trade: null,
        staffCount: null,
        financingStatus: null,
        welfare: null,
        discription: '',
        enterprisePic: '',
        address: '',
        safetyOfficerName: '',
        safetyOfficerPhone: '',
        enterpriseLogo: '',
        enterpriseOfficePic: '',
        representativePhone: '',
        type: null,
      },
      bizPath: '',
    }
  },
  created() {
    this.bizPath = this.$currentDate.getDate()
    if (this.token) {
      this.getUserIfo()
      this.getAuthInfo()
    }
  },
  activated() {
    this.bizPath = this.$currentDate.getDate()
    if (this.token) {
      this.getUserIfo()
      this.getAuthInfo()
    }
  },
  computed: {
    ...mapState({
      authMessage: (state) => state.auth.authMessage,
      token: (state) => state.user.token,
    }),
  },
  methods: {
    ...mapActions(['reviseUserInfo']),
    ...mapGetters(['userInfo']),
    async getUserIfo() {
      try {
        const params = {
          id: this.userInfo().id,
        }
        const res = await userQueryById(params)
        // console.log(res)
        if (res.success) {
          this.userData = res.result
          this.$store.dispatch('reviseUserInfo', res.result)
        } else {
          this.$message.error(res.message)
        }
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    // 获取当前登录用户认证信息
    async getAuthInfo() {
      try {
        const res = await yqmsEnterpriseInfo({})
        if (res.success) {
          console.log(res)
          this.enterpriseData = res.result
        }
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    editInfo() {
      this.btnState = true
      this.readonlyState = false
      this.disabledState = false
    },
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.setUserInfo()
        } else {
          return false
        }
      })
    },
    setUserInfo() {
      const params = {
        id: this.userInfo().id,
        realname: this.userData.realname,
        // phone: this.userData.phone,
        email: this.userData.email,
      }
      editUserInfo(params)
        .then((res) => {
          // console.log(res)
          if (res.success) {
            this.$message.success('账号信息保存成功！')
            this.getUserIfo()
          } else {
            this.$message.error(res.message)
          }
          this.btnState = false
          this.readonlyState = true
          this.disabledState = true
          // this.loading = false
          this.$emit('setLoading')
        })
        .catch((err) => {
          this.$message.error(err.message)
          // this.loading = false
          this.$emit('setLoading')
        })
    },
    submitEnterpriseInfo() {
      this.$refs.enterprisForm.validate((valid) => {
        if (valid) {
          this.setEnterpriseInfo()
        }
      })
    },
    setEnterpriseInfo() {
      this.Eloading = true
      const params = {
        id: this.enterpriseData.id,
        phone: this.enterpriseData.phone,
        email: this.enterpriseData.email,
        trade: this.enterpriseData.trade,
        staffCount: this.enterpriseData.staffCount,
        financingStatus: this.enterpriseData.financingStatus,
        welfare: this.enterpriseData.welfare,
        discription: this.enterpriseData.discription,
        enterprisePic: this.enterpriseData.enterprisePic,
        address: this.enterpriseData.address,
        safetyOfficerName: this.enterpriseData.safetyOfficerName,
        safetyOfficerPhone: this.enterpriseData.safetyOfficerPhone,
        enterpriseLogo: this.enterpriseData.enterpriseLogo,
        enterpriseOfficePic: this.enterpriseData.enterpriseOfficePic,
        representativePhone: this.enterpriseData.representativePhone,
        type: this.enterpriseData.type,
      }
      console.log(params)
      yqmsEnterpriseEdit(params)
        .then((res) => {
          if (res.success) {
            this.$message.success('企业信息保存成功！')
            this.getAuthInfo()
          } else {
            this.$message.error(res.message)
          }
          this.btnState = false
          this.readonlyState = true
          this.disabledState = true
          this.Eloading = false
        })
        .catch((err) => {
          this.$message.error(err.message)
          this.Eloading = false
        })
    },
  },
}
</script>
<style lang="less" scoped>
.card {
  background: #fff;
  padding: 50px 30px 50px 0;
}

.card-height {
  min-height: 627px;
}

.enterprise {
  background: #fff;
  .title {
    border-bottom: 1px solid #e5e5e5;
    .btn-style {
      margin: 0 20px;
    }
    .horn-title-style {
      width: 160px;
      height: 55px;
      line-height: 55px;
      background-color: #1285d6;
      position: relative;
      font-size: 22px;
      font-weight: 400;
      color: #ffffff;
      text-align: center;

      &::before {
        content: '';
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-top-color: rgb(18, 133, 214);
        top: 100%;
        left: 50%;
        position: absolute;
        margin-left: -12px;
      }
    }
  }

  .info {
    padding: 50px 30px 50px 0;
  }

  .e-pic-tips {
    margin-top: -20px;
    color: #999;
    font-size: 12px;
  }
}

.footer {
  margin-top: 25px;
  background: #fff;
  text-align: center;
  padding: 20px 0;

  .btn-style {
    height: 40px;
    line-height: 40px;
    padding: 0 30px;
    margin: 0 30px;
  }

  .edit-btn {
    &:focus {
      color: rgba(0, 0, 0, 0.65);
      border-color: #d9d9d9;
    }
  }
  .ant-input-r {
    background-color: #e8e6e6 !important;
    color: rgba(0, 0, 0, 0.25) !important;
  }
}
</style>
