<template>
  <div class="main-body">
    <div class="flex-wrap">
      <div class="main-body-left">
        <div class="user-info flex-wrap flex-vertical flex-center">
          <div class="head-portrait">
            <head-portrait-upload
              class="avatar-uploader"
              :bizPath="bizPath"
              v-model="userData.avatar"
              @change="handleChange"
            ></head-portrait-upload>
          </div>
          <div class="account-number t-hide">
            {{ userData.realname ? userData.realname : userData.username }}
          </div>
          <ul class="auth-state">
            <template v-if="authMessage != 101 && authMessage != 201">
              <li
                :class="[item.className, 'flex-wrap', 'flex-center']"
                v-for="(item, index) in getIdentity(authMessage)"
                :key="index"
                @click="handleClick(item)"
              >
                <div>{{ item.state }}</div>
              </li>
            </template>
            <li
              class="role flex-wrap flex-wrap-wrap"
              v-if="
                role.length > 0 && (authMessage == 101 || authMessage == 201)
              "
            >
              <div class="iden" v-for="(item, index) in role" :key="index">
                {{ item.roleName }}
              </div>
            </li>
          </ul>
        </div>
        <div class="nav">
          <ul>
            <li :class="[tabIndex === 0 ? 'active' : '']" @click="activeTab(0)">
              用户资料
            </li>
            <li :class="[tabIndex === 1 ? 'active' : '']" @click="activeTab(1)">
              我的活动
            </li>
            <li :class="[tabIndex === 2 ? 'active' : '']" @click="activeTab(2)">
              我的服务
            </li>
            <li :class="[tabIndex === 3 ? 'active' : '']" @click="activeTab(3)">
              我的消息
            </li>
          </ul>
        </div>
      </div>
      <div class="main-body-right flex-con">
        <div v-show="tabIndex === 0">
          <information
            ref="information"
            :key="tabIndex"
            v-if="tabIndex === 0"
          />
        </div>

        <div v-show="tabIndex === 1">
          <activity :key="tabIndex" v-if="tabIndex === 1" />
        </div>

        <div v-show="tabIndex === 2">
          <serviceList :key="tabIndex" v-if="tabIndex === 2" />
        </div>

        <div v-show="tabIndex === 3">
          <notice :key="tabIndex" v-if="tabIndex === 3" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Information from './UserInfo/Information'
import Activity from './Activity/Activity'
import ServiceList from './Service/ServiceList'
import Notice from './Notice/Notice'
import HeadPortraitUpload from '@/components/Assembly/HeadPortraitUpload'
import { editUserInfo, userQueryById } from '@/api/api'
export default {
  components: {
    Information,
    Activity,
    ServiceList,
    HeadPortraitUpload,
    Notice,
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: '',
      userData: {},
      rules: {},
      tabIndex: 0,
      identityList: [
        {
          key: 100,
          state: '认证中（请稍等）',
          name: '企业',
          className: 'ing',
        },
        {
          key: 101,
          state: '已认证',
          name: '企业',
          className: 'adopt',
        },
        {
          key: 102,
          state: '认证驳回',
          name: '企业',
          className: 'reject',
        },
        {
          key: 200,
          state: '认证中（请稍等）',
          name: '个人',
          className: 'ing',
        },
        {
          key: 201,
          state: '已认证',
          name: '个人',
          className: 'adopt',
        },
        {
          key: 202,
          state: '认证驳回',
          name: '个人',
          className: 'reject',
        },
        {
          key: 0,
          state: '未认证（立即认证)',
          name: '',
          className: 'not',
        },
      ],
      bizPath: '',
    }
  },
  computed: {
    ...mapState({
      authMessage: (state) => state.auth.authMessage,
      authInfo: (state) => state.auth.authInfo,
      userInfo: (state) => state.user.info,
      role: (state) => state.user.role,
    }),
  },
  beforeRouteLeave(to, from, next) {
    if (to.name != 'PersonalCenterActivityDetails') {
      this.tabIndex = 0
    }
    next()
  },
  created() {
    this.bizPath = this.$currentDate.getDate()
    this.getUserIfo()
    this.getAuth()
    this.getRole()
  },
  activated() {
    this.bizPath = this.$currentDate.getDate()
    this.getUserIfo()
    this.getAuth()
    this.getRole()
  },
  methods: {
    ...mapActions(['GetUserAuthStatus', 'GetRole']),
    handleClick(item) {
      if (item.key === 0) {
        this.$router.push({ path: '/authentication' })
      } else {
        this.$refs.information.tabIndex = 2
      }
    },
    getIdentity(key) {
      const list = this.identityList.filter((item) => item.key == key)
      return list
    },
    openInformation() {
      this.$refs.information.changeTab(2)
    },
    async getUserIfo() {
      try {
        const params = {
          id: this.userInfo.id,
        }
        const res = await userQueryById(params)
        if (res.success) {
          this.userData = res.result
        } else {
          this.$message.error(res.message)
        }
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    handleChange(path) {
      const params = {
        id: this.userInfo.id,
        avatar: path,
      }
      editUserInfo(params)
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message)
            this.getUserIfo()
          } else {
            this.$message.error(res.message)
          }
          this.btnState = false
          this.readonlyState = true
          this.loading = false
        })
        .catch((err) => {
          this.$message.error(err.message)
          this.loading = false
        })
    },
    activeTab(index) {
      this.tabIndex = index
      this.getAuth()
      this.getRole()
    },
    getAuth() {
      const id = this.userInfo.id
      this.GetUserAuthStatus(id)
        .then((res) => {
          // console.log(res)
        })
        .catch((err) => {})
    },
    getRole() {
      const id = this.userInfo.id
      this.GetRole(id)
        .then((res) => {
          // console.log(res)
        })
        .catch((err) => {})
    },
  },
}
</script>
<style lang="less" scoped>
.main-body {
  background: #f1f1f1;
  > div:nth-child(1) {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    padding: 50px 0 130px 0;

    .main-body-left {
      width: 296px;
      height: 683px;
      background: #ffffff;
      box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.18);

      .user-info {
        padding: 30px 0;
        border-bottom: 1px dashed #ccc;
        .head-portrait {
          width: 110px;
          height: 110px;

          .avatar-uploader {
            height: 100%;
          }
        }

        .account-number {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          margin: 13px 0 10px 0;
          max-width: 200px;
        }

        .auth-state {
          font-size: 14px;

          .not,
          .reject {
            color: #f12b2b;
            cursor: pointer;
          }

          .ing {
            color: #1285d6;
          }

          .adopt {
            color: #333;
            .iden {
              // width: 54px;
              display: inline;
              height: 24px;
              line-height: 24px;
              background: #1285d6;
              border-radius: 5px;
              color: #fff;
              text-align: center;
              margin-left: 5px;
              padding-left: 5px;
              padding-right: 5px;
            }
          }
        }
      }

      .nav {
        ul {
          text-align: center;
          li {
            font-size: 16px;
            padding: 28px 0;
            color: #666666;
            cursor: pointer;
            border-bottom: 1px dashed #cccccc;

            &.active {
              color: #1285d6;
              background: rgba(18, 133, 214, 0.2);
            }

            &:last-child {
              border: none;
            }

            &:hover {
              color: #1285d6;
              background: rgba(18, 133, 214, 0.2);
            }
          }
        }
      }
    }

    .main-body-right {
      margin-left: 24px;
    }

    .role {
      padding: 10px 30px;
      .iden {
        background: #1285d6;
        color: #fff;
        margin: 0 5px 5px 0;
        padding: 2px 5px;
        border-radius: 5px;
      }
    }
  }
}
</style>
