<template>
  <div>
    <div class="card">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        hideRequiredMark
      >
        <a-form-model-item ref="name" label="企业名称" prop="enterpriseName">
          <div class="item-style flex-wrap flex-h-center">
            <div class="flex-wrap flex-center item-icon">
              <img src="@/assets/auth3.png" alt="" width="25" height="22" />
            </div>
            <a-input
              v-model="form.enterpriseName"
              placeholder="请输入企业名称"
              :maxLength="60"
              :disabled="readonlyState"
            />
          </div>
        </a-form-model-item>

        <a-form-model-item ref="name" label="统一社会信用代码" prop="trustCode">
          <div class="item-style flex-wrap flex-h-center">
            <div class="flex-wrap flex-center item-icon">
              <img src="@/assets/auth8.png" alt="" width="22" height="23" />
            </div>
            <a-input
              v-model="form.trustCode"
              placeholder="请输入统一社会信用代码"
              :maxLength="18"
              :disabled="readonlyState"
            />
          </div>
        </a-form-model-item>

        <a-form-model-item
          ref="name"
          label="营业执照"
          prop="cardPic"
          class="img-upload"
        >
          <image-upload
            class="avatar-uploader"
            text="上传营业执照"
            :bizPath="bizPath"
            v-model="form.cardPic"
            :disabled="readonlyState"
          ></image-upload>
        </a-form-model-item>

        <a-form-model-item
          ref="name"
          label="法人姓名"
          prop="representativeName"
        >
          <div class="item-style flex-wrap flex-h-center">
            <div class="flex-wrap flex-center item-icon">
              <img src="@/assets/auth5.png" alt="" width="24" height="17" />
            </div>
            <a-input
              v-model="form.representativeName"
              placeholder="请输入法人姓名"
              :maxLength="30"
              :disabled="readonlyState"
            />
          </div>
        </a-form-model-item>

        <a-form-model-item
          ref="name"
          label="法人身份证号码"
          prop="representativeCardid"
        >
          <div class="item-style flex-wrap flex-h-center">
            <div class="flex-wrap flex-center item-icon">
              <img src="@/assets/auth6.png" alt="" width="23" height="16" />
            </div>
            <a-input
              v-model="form.representativeCardid"
              placeholder="请输入法人身份证号码"
              :maxLength="18"
              :disabled="readonlyState"
            />
          </div>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="footer" v-show="authData.examStatus == 2">
      <a-button class="btn-style" type="primary" @click="submit">
        重新提交
      </a-button>
    </div>
  </div>
</template>
<script>
import reg from '@/api/regular'
import { mapState, mapActions, mapGetters } from 'vuex'
import ImageUpload from '@/components/Assembly/ImageUpload'
import { yqmsEnterpriseInfo, yqmsEnterpriseEdit } from '@/api/api'
export default {
  components: {
    ImageUpload,
  },
  data() {
    let validateImg = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请上传营业执照'))
      } else {
        callback()
      }
    }
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 14 },
      form: {
        enterpriseName: '',
        trustCode: '',
        cardPic: '',
        representativeName: '',
        representativeCardid: '',
      },
      rules: {
        enterpriseName: [
          {
            required: true,
            message: '请输入企业名称',
            trigger: 'blur',
          },
        ],
        trustCode: [
          {
            required: true,
            message: '请输入统一社会信用代码',
            trigger: 'blur',
          },
          {
            required: true,
            pattern: reg.charterNum,
            message: '请输入正确的统一社会信用代码',
            trigger: 'blur',
          },
        ],
        cardPic: [{ validator: validateImg, trigger: 'change' }],
        representativeName: [
          {
            required: true,
            message: '请输入法人姓名',
            trigger: 'blur',
          },
        ],
        representativeCardid: [
          {
            required: true,
            message: '请输入法人身份证号码',
            trigger: 'blur',
          },
          {
            required: true,
            pattern: reg.cardNum,
            message: '请输入正确的法人身份证号码',
            trigger: 'blur',
          },
        ],
      },
      readonlyState: true,
      bizPath: '',
    }
  },
  computed: {
    ...mapState({
      authData: (state) => state.auth.authInfo,
      token: (state) => state.user.token,
    }),
  },
  created() {
    this.bizPath = this.$currentDate.getDate()
    if (this.token) {
      this.getAuthInfo()
    }
    this.authData.examStatus == 2
      ? (this.readonlyState = false)
      : (this.readonlyState = true)
  },
  activated() {
    this.bizPath = this.$currentDate.getDate()
    if (this.token) {
      this.getAuthInfo()
    }
    this.authData.examStatus == 2
      ? (this.readonlyState = false)
      : (this.readonlyState = true)
  },
  methods: {
    ...mapActions(['GetUserAuthStatus', 'GetRole']),
    ...mapGetters(['userInfo']),
    // 获取当前登录用户认证信息
    async getAuthInfo() {
      try {
        const res = await yqmsEnterpriseInfo({})
        if (res.success) {
          if (this.$refs.ruleForm) {
            this.$refs.ruleForm.clearValidate()
          }
          this.form = res.result
          res.result.examStatus === 2
            ? (this.readonlyState = false)
            : (this.readonlyState = true)
        }
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const params = {
            ...this.form,
          }
          params.examStatus = 0
          yqmsEnterpriseEdit(params)
            .then((res) => {
              if (res.success) {
                this.getAuth()
                this.getRole()
                this.$message.success(res.message)
              }
            })
            .catch((err) => {
              this.$message.error(err.message)
            })
        }
      })
    },
    getAuth() {
      const id = this.userInfo().id
      this.GetUserAuthStatus(id)
        .then((res) => {})
        .catch((err) => {})
    },
    getRole() {
      const id = this.userInfo().id
      this.GetRole(id)
        .then((res) => {})
        .catch((err) => {})
    },
  },
}
</script>
<style lang="less" scoped>
/deep/ .card {
  background: #fff;
  min-height: 600px;
  padding: 50px 30px 50px 0;
  .ant-form {
    .item-style {
      border: 1px solid #dcdcdc;
      border-radius: 5px;
      .item-icon {
        width: 70px;
        border-right: 1px solid #ddd;
      }

      .ant-input {
        border: none;
        padding: 20px 15px;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .ant-select {
      .ant-select-selection {
        border: none;
      }
    }

    .ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active {
      box-shadow: none;
    }
  }
}

.footer {
  margin-top: 25px;
  background: #fff;
  text-align: center;
  padding: 20px 0;

  .btn-style {
    height: 40px;
    line-height: 40px;
    padding: 0 30px;
    margin: 0 30px;
  }
}
</style>
