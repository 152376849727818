<template>
  <div>
    <a-modal
      width="80%"
      v-model="visible"
      :title="title"
      :okButtonProps="{ style: { display: 'none' } }"
      cancelText="关闭"
    >
      <div class="form-content">
        <k-form-build ref="kfb" :value="kfdJsonData" :disabled="disabled" />
      </div>
    </a-modal>
  </div>
</template>
<script>
import { KFormBuild } from '@/components/k-form-design/index'
import { getFormByProcessDefinitionId } from '@/api/process'
export default {
  components: {
    KFormBuild,
  },
  data() {
    return {
      visible: false,
      kfdJsonData: {},
      title: '',
      disabled: true,
    }
  },
  methods: {
    showModal(item) {
      this.title = item.modelName
      this.visible = true
      this.getKfdJson(item)
    },
    async getKfdJson(item) {
      try {
        const params = {
          modelId: item.modelId,
          processDefinitionId: item.processDefinitionId,
        }
        const res = await getFormByProcessDefinitionId(params)
        if (res.success) {
          this.modelId = item.modelId
          this.formId = item.formId
          this.formDataId = item.formDataId
          this.kfdJsonData = JSON.parse(res.result.htmlJson)
          // this.desformApplyVisible = true
          this.$nextTick(() => {
            this.$refs.kfb.setData(JSON.parse(item.formData))
          })
        } else {
          this.$message.warning(res.message)
        }
      } catch (err) {
        console.log(err)
      }
    },
    handleOk(e) {
      console.log(e)
      this.visible = false
    },
  },
}
</script>
