<template>
  <div>
    <div class="card">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        hideRequiredMark
      >
        <a-form-model-item label="姓名" prop="examUserName">
          <div class="item-style flex-wrap flex-h-center">
            <div class="flex-wrap flex-center item-icon">
              <img src="@/assets/auth5.png" alt="" width="24" height="17" />
            </div>
            <a-input
              v-model="form.examUserName"
              placeholder="请输入姓名"
              :maxLength="30"
              :read-only="readonlyState"
            />
          </div>
        </a-form-model-item>

        <a-form-model-item label="身份证号码" prop="cardId">
          <div class="item-style flex-wrap flex-h-center">
            <div class="flex-wrap flex-center item-icon">
              <img src="@/assets/auth6.png" alt="" width="23" height="16" />
            </div>
            <a-input
              v-model="form.cardId"
              placeholder="请输入身份证号码"
              :maxLength="18"
              :read-only="readonlyState"
            />
          </div>
        </a-form-model-item>

        <a-form-model-item
          label="证件照片"
          prop="certificates"
          class="img-upload"
        >
          <a-row :gutter="24">
            <a-col :span="12">
              <image-upload
                class="avatar-uploader"
                text="上传身份证人像面"
                :bizPath="bizPath"
                v-model="form.cardUpPic"
                :disabled="readonlyState"
              ></image-upload>
            </a-col>
            <a-col :span="12">
              <image-upload
                class="avatar-uploader"
                text="上传身份证国徽面"
                :bizPath="bizPath"
                v-model="form.cardDownPic"
                :disabled="readonlyState"
              ></image-upload>
            </a-col>
          </a-row>
        </a-form-model-item>

        <a-form-model-item label="所属企业" prop="enterpriseId">
          <div class="item-style flex-wrap flex-h-center">
            <div class="flex-wrap flex-center item-icon">
              <img src="@/assets/auth3.png" alt="" width="25" height="22" />
            </div>
            <a-select
              v-model="form.enterpriseId"
              placeholder="请选择所属企业"
              :disabled="readonlyState"
            >
              <a-select-option v-for="item in enterpriseList" :key="item.id">
                {{ item.enterpriseName }}
              </a-select-option>
            </a-select>
          </div>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="footer" v-show="authData.examStatus == 2">
      <a-button class="btn-style" type="primary" @click="submit">
        重新提交
      </a-button>
    </div>
  </div>
</template>
<script>
import reg from '@/api/regular'
import { mapState, mapActions, mapGetters } from 'vuex'
import ImageUpload from '@/components/Assembly/ImageUpload'
import { yqmsPersonexamInfo, yqmsPersonexamEdit } from '@/api/api'
import { yqmsEnterpriseListAll } from '@/api/auth'
export default {
  components: {
    ImageUpload,
  },
  data() {
    let validateImg = (rule, value, callback) => {
      if (this.form.cardUpPic === '') {
        callback(new Error('请上传身份证人像面'))
      } else if (this.form.cardDownPic === '') {
        callback(new Error('请上传身份证国徽面'))
      } else {
        callback()
      }
    }
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 14 },
      form: {
        examUserName: '',
        cardId: '',
        cardUpPic: '',
        cardDownPic: '',
        enterpriseId: undefined,
      },
      rules: {
        examUserName: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur',
          },
        ],
        cardId: [
          {
            required: true,
            message: '请输入身份证号码',
            trigger: 'blur',
          },
          {
            required: true,
            pattern: reg.cardNum,
            message: '请输入正确的身份证号码',
            trigger: 'blur',
          },
        ],
        certificates: [{ validator: validateImg, trigger: 'change' }],
        enterpriseId: [
          {
            required: true,
            message: '请选择所属企业',
            trigger: 'change',
          },
        ],
      },
      enterpriseList: [],
      readonlyState: true,
      bizPath: '',
    }
  },
  computed: {
    ...mapState({
      authData: (state) => state.auth.authInfo,
    }),
  },
  created() {
    this.bizPath = this.$currentDate.getDate()
    this.authData.examStatus == 2
      ? (this.readonlyState = false)
      : (this.readonlyState = true)
    this.getAuthInfo()
    this.getList()
  },
  activated() {
    this.bizPath = this.$currentDate.getDate()
    this.authData.examStatus == 2
      ? (this.readonlyState = false)
      : (this.readonlyState = true)
    this.getAuthInfo()
    this.getList()
  },
  methods: {
    ...mapActions(['GetUserAuthStatus', 'GetRole']),
    ...mapGetters(['userInfo']),
    // 获取当前登录用户认证信息
    async getAuthInfo() {
      try {
        const res = await yqmsPersonexamInfo({})
        if (res.code == 200) {
          if (this.$refs.ruleForm) {
            this.$refs.ruleForm.clearValidate()
          }
          this.form = res.result
          res.result.examStatus === 2
            ? (this.readOnly = false)
            : (this.readOnly = true)
        }
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    async getList() {
      try {
        const res = await yqmsEnterpriseListAll({})
        if (res.code == 200) {
          this.enterpriseList = res.result
        } else {
          this.$message.error(res.message)
        }
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const params = {
            ...this.form,
          }
          params.examStatus = 0
          console.log(params)
          yqmsPersonexamEdit(params)
            .then((res) => {
              if (res.success) {
                this.getAuth()
                this.getRole()
                this.$message.success(res.message)
              } else {
                this.$message.error(res.message)
              }
            })
            .catch((err) => {
              this.$message.error(err.message)
            })
        }
      })
    },
    getAuth() {
      const id = this.userInfo().id
      this.GetUserAuthStatus(id)
        .then((res) => {})
        .catch((err) => {})
    },
    getRole() {
      const id = this.userInfo().id
      this.GetRole(id)
        .then((res) => {})
        .catch((err) => {})
    },
  },
}
</script>
<style lang="less" scoped>
/deep/ .card {
  background: #fff;
  min-height: 600px;
  padding: 50px 30px 50px 0;
  .ant-form {
    .item-style {
      border: 1px solid #dcdcdc;
      border-radius: 5px;
      .item-icon {
        width: 70px;
        border-right: 1px solid #ddd;
      }

      .ant-input {
        border: none;
        padding: 20px 15px;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .ant-select {
      .ant-select-selection {
        border: none;
      }
    }

    .ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active {
      box-shadow: none;
    }

    .ant-select-disabled .ant-select-selection {
      background: #fff;
    }
  }
}

.footer {
  margin-top: 25px;
  background: #fff;
  text-align: center;
  padding: 20px 0;

  .btn-style {
    height: 40px;
    line-height: 40px;
    padding: 0 30px;
    margin: 0 30px;
  }
}
</style>
