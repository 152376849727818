import { render, staticRenderFns } from "./HeadPortraitUpload.vue?vue&type=template&id=c5f2609a&scoped=true&"
import script from "./HeadPortraitUpload.vue?vue&type=script&lang=js&"
export * from "./HeadPortraitUpload.vue?vue&type=script&lang=js&"
import style0 from "./HeadPortraitUpload.vue?vue&type=style&index=0&id=c5f2609a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5f2609a",
  null
  
)

export default component.exports