<template>
  <div>
    <div
      class="images"
      v-viewer="{ movable: false }"
      style="display: none"
      v-if="showPreview"
    >
      <img :src="getAvatarView()" />
    </div>
    <a-upload
      name="file"
      listType="picture-card"
      :multiple="isMultiple"
      :action="uploadAction"
      :headers="headers"
      :data="{ biz: bizPath }"
      :fileList="fileList"
      :beforeUpload="beforeUpload"
      :disabled="disabled"
      @change="handleChange"
      @preview="handlePreview"
    >
      <div v-if="fileList.length < 9">
        <img
          v-if="!uploadLoading"
          src="@/assets/auth2.png"
          alt=""
          width="63"
          height="63"
        />
        <a-icon v-else type="loading" />
        <div class="ant-upload-text">{{ text }}</div>
      </div>
    </a-upload>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel()">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    <div
      class="preview"
      v-if="picUrl && showPreview"
      @click.stop="handlePreviewImage($event)"
    >
      <img src="@/assets/yl.png" alt="" width="24" height="24" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { getFileAccessHttpUrl } from '@/api/manage'

const uidGenerator = () => {
  return '-' + parseInt(Math.random() * 10000 + 1, 10)
}
const getFileName = (path) => {
  if (path.lastIndexOf('\\') >= 0) {
    const reg = new RegExp('\\\\', 'g')
    path = path.replace(reg, '/')
  }
  return path.substring(path.lastIndexOf('/') + 1)
}
export default {
  name: 'ImageUpload',
  data() {
    return {
      uploadAction: window._CONFIG['domianURL'] + '/sys/common/upload',
      uploadLoading: false,
      picUrl: false,
      headers: {},
      fileList: [],
      previewImage: '',
      previewVisible: false,
    }
  },
  props: {
    text: {
      type: String,
      required: false,
      default: '上传',
    },
    /* 这个属性用于控制文件上传的业务路径*/
    bizPath: {
      type: String,
      required: false,
      default: 'temp',
    },
    value: {
      type: [String, Array],
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    showPreview: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    value(val) {
      if (val instanceof Array) {
        this.initFileList(val.join(','))
      } else {
        this.initFileList(val)
      }
      if (!val || val.length == 0) {
        this.picUrl = false
      }
    },
  },
  created() {
    const token = Vue.ls.get(ACCESS_TOKEN)
    this.headers = { 'X-Access-Token': token }
  },
  methods: {
    initFileList(paths) {
      if (!paths || paths.length == 0) {
        this.fileList = []
        return
      }
      this.picUrl = true
      const fileList = []
      const arr = paths.split(',')
      for (var a = 0; a < arr.length; a++) {
        const url = getFileAccessHttpUrl(arr[a])
        fileList.push({
          uid: uidGenerator(),
          name: getFileName(arr[a]),
          status: 'done',
          url: url,
          response: {
            status: 'history',
            message: arr[a],
          },
        })
      }
      this.fileList = fileList
    },
    beforeUpload: function (file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('请上传正确格式的图片!')
      }
      return isJpgOrPng
    },
    handleChange(info) {
      console.log(info)
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        this.picUrl = false
      }

      let fileList = info.fileList
      if (info.file.status === 'done') {
        if (info.file.response.success) {
          this.picUrl = true
          fileList = fileList.map((file) => {
            if (file.response) {
              file.url = file.response.message
            }
            return file
          })
        }
        // this.$message.success(`${info.file.name} 上传成功!`);
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败.`)
        this.uploadLoading = false
      } else if (info.file.status === 'removed') {
        this.handleDelete(info.file)
        this.uploadLoading = false
      } else if (!info.file.status) {
        fileList = info.fileList.slice(0, -1)
      }
      this.fileList = fileList
      if (info.file.status === 'done' || info.file.status === 'removed') {
        this.handlePathChange()
      }
    },
    // 预览
    handlePreview(file) {
      this.previewImage = file.url || file.thumbUrl
      this.previewVisible = true
    },
    getAvatarView() {
      if (this.fileList.length > 0) {
        const url = this.fileList[0].url
        return getFileAccessHttpUrl(url)
      }
    },
    handlePathChange() {
      console.log(this.fileList)
      const uploadFiles = this.fileList
      let path = ''
      if (!uploadFiles || uploadFiles.length == 0) {
        path = ''
      }
      const arr = []
      if (!this.isMultiple) {
        arr.push(uploadFiles[uploadFiles.length - 1].response.message)
      } else {
        for (var a = 0; a < uploadFiles.length; a++) {
          arr.push(uploadFiles[a].response.message)
        }
      }
      if (arr.length > 0) {
        path = arr.join(',')
      }
      this.uploadLoading = false
      this.$emit('change', path)
    },
    handleDelete(file) {
      // 如有需要新增 删除逻辑
      console.log(file)
    },
    handleCancel() {
      this.close()
      this.previewVisible = false
    },
    close() {},
    //单张图片预览
    handlePreviewImage() {
      const viewer = this.$el.querySelector('.images').$viewer
      viewer.show()
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
}
</script>

<style lang="less" scoped></style>
