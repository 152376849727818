<template>
  <div class="content">
    <div class="title">
      <div class="horn-title-style">我的服务</div>
    </div>
    <div class="table-content">
      <s-table
        ref="table"
        size="default"
        rowKey="processInstanceId"
        :columns="columns"
        :data="loadData"
        bordered
      >
        <span slot="action" slot-scope="record">
          <template>
            <div
              @click="opemServiceModal(record)"
              style="color: #1285d6; cursor: pointer"
            >
              详情
            </div>
          </template>
        </span>
      </s-table>
    </div>
    <service-modal ref="serviceModal" />
    <conference-room-modal ref="conferenceRoomModal" />
  </div>
</template>

<script>
import { STable } from '@/components'
import { mapGetters } from 'vuex'
import { getMyCreateTaskByPage } from '@/api/api'
import ServiceModal from './ServiceModal'
import ConferenceRoomModal from './ConferenceRoomModal'
export default {
  name: 'NoticeTable',
  components: {
    STable,
    ServiceModal,
    ConferenceRoomModal,
  },
  data() {
    return {
      // 表头
      columns: [
        {
          title: '服务名称',
          dataIndex: 'modelName',
          align: 'center',
        },
        {
          title: '创建时间',
          dataIndex: 'startTime',
          align: 'center',
        },
        {
          title: '业务状态',
          dataIndex: 'status',
          align: 'center',
          customRender: (type) => {
            if (type === 0) {
              return '未提交'
            } else if (type === 1) {
              return '处理中'
            } else if (type === 2) {
              return '已处理'
            } else if (type === 3) {
              return '已驳回'
            } else if (type === 4) {
              return '未通过'
            }
          },
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'action' },
          align: 'center',
        },
      ],
      // 当前编辑的对象
      currentEditData: {
        title: '',
        showSite: '',
        jumpText: '',
        jumpType: '',
        jumpUrl: '',
      },
    }
  },
  methods: {
    ...mapGetters(['userInfo']),
    async loadData(parameter) {
      const params = {
        ...parameter,
        userId: this.userInfo().id,
      }
      try {
        const res = await getMyCreateTaskByPage(params)
        if (res.code == 200) {
          console.log(res)
        } else {
          this.$message.warn(res.message)
        }
        return Promise.resolve(res)
      } catch (e) {
        this.$message.error('网络错误', 1)
      }

      return Promise.reject('网络错误')
    },
    opemServiceModal(record) {
      console.log(record)
      if (record.tableName) {
        this.$refs.conferenceRoomModal.showModal(record)
      } else {
        this.$refs.serviceModal.showModal(record)
      }
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  background: #fff;
  min-height: 681px;

  .title {
    border-bottom: 1px solid #e5e5e5;
    .horn-title-style {
      width: 160px;
      height: 55px;
      line-height: 55px;
      background-color: #1285d6;
      position: relative;
      font-size: 22px;
      font-weight: 400;
      color: #ffffff;
      text-align: center;

      &::before {
        content: '';
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-top-color: rgb(18, 133, 214);
        top: 100%;
        left: 50%;
        position: absolute;
        margin-left: -12px;
      }
    }
  }

  .table-content {
    padding: 30px;
  }
}
</style>
