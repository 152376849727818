<template>
  <div>
    <a-modal
      :width="width"
      v-model="visible"
      title="通知消息"
      centered
      :okButtonProps="{ style: { display: 'none' } }"
      cancelText="关闭"
    >
      <template slot="footer">
        <a-button @click="close"> 关闭 </a-button>
        <a-button
          type="primary"
          @click="getWorkflow"
          v-if="this.noticeData.busId"
        >
          详情
        </a-button>
      </template>
      <div>
        <div class="header">
          <div class="title">{{ noticeData.titile }}</div>
          <div class="sender">发布人：{{ noticeData.sender_dictText }}</div>
          <div class="time">发布时间：{{ noticeData.sendTime }}</div>
        </div>
        <div class="content">{{ noticeData.msgContent }}</div>
      </div>
    </a-modal>

    <service-modal ref="serviceModal" />
    <conference-room-modal ref="conferenceRoomModal" />
  </div>
</template>
<script>
import ServiceModal from '../Service/ServiceModal'
import ConferenceRoomModal from '../Service/ConferenceRoomModal'
import { getFormDataByTaskId } from '@/api/api'
export default {
  components: {
    ServiceModal,
    ConferenceRoomModal,
  },
  data() {
    return {
      width: 800,
      visible: false,
      noticeData: {},
    }
  },
  methods: {
    showModal(record) {
      this.noticeData = record
      this.visible = true
    },
    close() {
      this.visible = false
    },
    openDetails(record) {
      if (record.tableName) {
        this.$refs.conferenceRoomModal.showModal(record)
      } else {
        this.$refs.serviceModal.showModal(record)
      }
    },
    async getWorkflow() {
      const id = this.noticeData.busId
      try {
        const res = await getFormDataByTaskId(id)
        if (res.success) {
          this.openDetails(res.result)
        } else {
          this.$message.warning(res.message)
        }
      } catch (err) {
        this.$message.console.error(err.message)
      }
    },
  },
}
</script>
<style lang="less" scoped>
.header {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;
  .title {
    font-size: 16px;
    color: #333;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .sender,
  .time {
    color: #666;
  }

  .time {
    margin-top: 5px;
  }
}

.content {
  padding-top: 20px;
  font-size: 16px;
  line-height: 1.5;
}
</style>
