<template>
  <div class="content">
    <div class="tab-nav flex-wrap flex-h-center">
      <div
        :class="['horn-title-style', tabIndex === 0 ? 'active' : '']"
        @click="changeTab(0)"
      >
        我的活动
      </div>
      <div
        :class="['horn-title-style', tabIndex === 1 ? 'active' : '']"
        @click="changeTab(1)"
      >
        未开始
      </div>
      <div
        :class="['horn-title-style', tabIndex === 2 ? 'active' : '']"
        @click="changeTab(2)"
      >
        进行中
      </div>
      <div
        :class="['horn-title-style', tabIndex === 3 ? 'active' : '']"
        @click="changeTab(3)"
      >
        已结束
      </div>
    </div>
    <div class="tab-content">
      <div class="example" v-if="loading">
        <a-spin size="large" />
      </div>
      <div
        class="flex-wrap flex-vertical flex-center not-img"
        v-else-if="this.activityList.length <= 0"
      >
        <img src="@/assets/zwsj.png" alt="" />
        <div>暂无数据</div>
      </div>
      <ul
        class="flex-wrap flex-wrap-wrap"
        v-else-if="this.activityList.length > 0"
      >
        <li
          v-for="(item, index) in activityList"
          :key="index"
          @click="
            $router.push({
              name: 'PersonalCenterActivityDetails',
              params: { id: item.id },
            })
          "
        >
          <div class="activity-img">
            <img :src="item.imageUrl" alt="" />
          </div>
          <div class="activity-info">
            <div class="name t-hide">{{ item.title }}</div>
            <div class="sponsor">主讲人：{{ item.mainSpeaker }}</div>
            <div class="date">时间：{{ item.startTime }}</div>
          </div>
          <div class="state-iden flex-wrap flex-direction-row-reverse">
            <div>
              {{
                item.status === 1
                  ? '未开始'
                  : item.status === 2
                  ? '已开始'
                  : item.status === 3
                  ? '已结束'
                  : ''
              }}
            </div>
          </div>
        </li>
      </ul>

      <div class="paging">
        <a-pagination
          v-model="page.current"
          :pageSize="page.pageSize"
          :total="page.total"
          hideOnSinglePage
          @change="changePage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { yqmsActivityMyApplyPage } from '@/api/api'
export default {
  data() {
    return {
      page: {
        pageSize: 6,
        current: 1,
        total: 0,
      },
      tabIndex: 0,
      loading: false,
      activityList: [],
    }
  },
  created() {
      this.getMyActivity()
  },
  activated() {
      this.getMyActivity()
  },
  methods: {
    async getMyActivity() {
      this.loading = true
      try {
        const params = {
          pageNo: this.page.current,
          pageSize: this.page.pageSize,
          status: this.tabIndex == 0 ? '' : this.tabIndex,
        }
        const res = await yqmsActivityMyApplyPage(params)
        if (res.success) {
          this.activityList = res.result.records
          this.page.current = res.result.current
          this.page.total = res.result.total
          this.loading = false
        } else {
          this.loading = false
          this.$message.error(res.message)
        }
      } catch (err) {
        this.loading = false
        this.$message.error(err.message)
      }
    },
    changeTab(index) {
      this.tabIndex = index
      this.page.current = 1
      this.getMyActivity()
    },
    changePage(page) {
      this.page.current = page
      this.getActivityList()
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  background: #fff;
  min-height: 681px;

  .tab-nav {
    background: #fff;
    border-bottom: 1px solid #e5e5e5;
    .horn-title-style {
      cursor: pointer;
      width: 160px;
      height: 55px;
      line-height: 55px;
      position: relative;
      font-size: 22px;
      font-weight: 400;
      color: #333;
      text-align: center;

      &::before {
        content: '';
        width: 0;
        height: 0;
        border: 10px solid transparent;
        top: 100%;
        left: 50%;
        position: absolute;
        margin-left: -12px;
      }
    }

    .active {
      background-color: #1285d6;
      color: #fff;
      &::before {
        border-top-color: rgb(18, 133, 214);
      }
    }
  }

  .tab-content {
    ul {
      padding: 30px 15px;
      li {
        width: 260px;
        background: #ffffff;
        border: 1px solid #eee;
        border-radius: 5px;
        cursor: pointer;
        margin: 11px;
        .activity-img {
          img {
            width: 100%;
            height: 178px;
            border-radius: 5px 5px 0 0;
          }
        }

        .activity-info {
          padding: 0 11px;
          .name {
            font-size: 14px;
            color: #333333;
            margin-top: 12px;
          }

          .sponsor,
          .date {
            font-size: 12px;
            color: #999999;
          }

          .sponsor {
            margin: 12px 0 6px 0;
          }

          .date {
            margin-bottom: 9px;
          }
        }

        .state-iden {
          div {
            padding: 6px 20px;
            background: #d0e7f7;
            border-radius: 5px 0 5px 0;
            font-size: 14px;
            color: #1285d6;
          }

          .state-end {
            background: #eeeeee;
            color: #999999;
          }
        }
      }
    }

    .paging {
      text-align: right;
      padding: 0 15px;
    }
  }

  .example {
    text-align: center;
    padding: 200px 0;
  }

  .not-img {
    font-size: 18px;
    color: #9cb9fa;
    padding: 100px 0;
    > div {
      margin-top: 20px;
    }
  }
}
</style>
