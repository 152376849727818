<template>
  <div class="content">
    <div class="tab-nav flex-wrap flex-h-center">
      <div
        :class="['horn-title-style', tabIndex === index ? 'active' : '']"
        @click="changeTab(index)"
        v-for="(item, index) in tabNav"
        :key="index"
      >
        {{ item }}
      </div>
    </div>
    <div class="table-content">
      <s-table
        ref="table"
        size="default"
        rowKey="processInstanceId"
        :columns="columns"
        :data="loadData"
        :pageSize="10"
        :pageNum="1"
        bordered
      >
        <span slot="action" slot-scope="record">
          <template>
            <div
              @click="opemNoticeModal(record)"
              style="color: #1285d6; cursor: pointer"
            >
              查看
            </div>
          </template>
        </span>
      </s-table>
    </div>
    <notice-modal ref="noticeModal" />
  </div>
</template>

<script>
import { STable } from '@/components'
import { mapGetters } from 'vuex'
import { getUserNoticeInfo, editByAnntIdAndUserId } from '@/api/api'
import NoticeModal from './NoticeModal'
export default {
  name: 'NoticeTable',
  components: {
    STable,
    NoticeModal,
  },
  data() {
    return {
      // 表头
      columns: [
        {
          title: '标题',
          align: 'center',
          dataIndex: 'titile',
        },
        {
          title: '消息类型',
          align: 'center',
          dataIndex: 'msgCategory',
          customRender: (text) => {
            if (text == '1') {
              return '通知公告'
            } else if (text == '2') {
              return '系统消息'
            } else {
              return text
            }
          },
        },
        {
          title: '发布人',
          align: 'center',
          dataIndex: 'sender_dictText',
        },
        {
          title: '发布时间',
          align: 'center',
          dataIndex: 'sendTime',
        },
        {
          title: '阅读状态',
          align: 'center',
          dataIndex: 'readFlag',
          customRender: (text) => {
            if (text == '0') {
              return '未读'
            } else if (text == '1') {
              return '已读'
            } else {
              return text
            }
          },
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'action' },
          align: 'center',
        },
      ],
      tabIndex: 0,
      tabNav: ['我的消息', '未读', '已读'],
      queryParam: {},
    }
  },
  methods: {
    ...mapGetters(['userInfo']),
    async loadData(parameter) {
      const params = {
        ...parameter,
        ...this.queryParam,
      }
      try {
        const res = await getUserNoticeInfo(params)
        if (res.success) {
          // console.log(res)
        } else {
          this.$message.warn(res.message)
        }
        return Promise.resolve(res)
      } catch (e) {
        this.$message.error('网络错误', 1)
      }

      return Promise.reject('网络错误')
    },
    opemNoticeModal(record) {
      this.$refs.noticeModal.showModal(record)
      this.syncHeadNotic(record.anntId)
    },
    syncHeadNotic(anntId) {
      const params = {
        anntId: anntId,
      }
      editByAnntIdAndUserId(params).then((res) => {
        if (res.success) {
          this.$refs.table.refresh()
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    changeTab(index) {
      this.tabIndex = index
      this.queryParam.readFlag = index == 1 ? 0 : index == 2 ? 1 : ''
      this.$refs.table.refresh(true)
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  background: #fff;
  min-height: 681px;

  .tab-nav {
    background: #fff;
    border-bottom: 1px solid #e5e5e5;
    .horn-title-style {
      cursor: pointer;
      width: 160px;
      height: 55px;
      line-height: 55px;
      position: relative;
      font-size: 22px;
      font-weight: 400;
      color: #333;
      text-align: center;

      &::before {
        content: '';
        width: 0;
        height: 0;
        border: 10px solid transparent;
        top: 100%;
        left: 50%;
        position: absolute;
        margin-left: -12px;
      }
    }

    .active {
      background-color: #1285d6;
      color: #fff;
      &::before {
        border-top-color: rgb(18, 133, 214);
      }
    }
  }

  .table-content {
    padding: 30px;
  }
}
</style>
