import { render, staticRenderFns } from "./AuthPersonalInfo.vue?vue&type=template&id=6a4f205a&scoped=true&"
import script from "./AuthPersonalInfo.vue?vue&type=script&lang=js&"
export * from "./AuthPersonalInfo.vue?vue&type=script&lang=js&"
import style0 from "./AuthPersonalInfo.vue?vue&type=style&index=0&id=6a4f205a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a4f205a",
  null
  
)

export default component.exports