<template>
  <div>
    <div class="content">
      <div class="nav-box flex-wrap flex-h-center flex-between">
        <div class="tab-nav flex-wrap flex-h-center">
          <div
            :class="['horn-title-style', tabIndex === 1 ? 'active' : '']"
            @click="changeTab(1)"
          >
            账号信息
          </div>
          <div
            :class="['horn-title-style', tabIndex === 2 ? 'active' : '']"
            @click="changeTab(2)"
            v-if="authMessage !== 0"
          >
            认证信息
          </div>
        </div>
        <div class="nav-box-right">
          <div class="tips" v-if="authMessage === 100 || authMessage === 200 || authMessage === 102 || authMessage === 202">*企业认证成功可编辑信息</div>
          <a-button
            v-if="authMessage === 101 || authMessage === 201"
            class="btn-style"
            type="primary"
            @click="submit"
            :loading="loading"
            >保存</a-button
          > 
        </div>
      </div>
      <div class="tab-content">
        <div v-show="tabIndex === 1">
          <basicInfo ref="basicInfo" :key="tabIndex" @setLoading="setLoading"/>
        </div>
        <div v-show="tabIndex === 2">
          <authenticationInfo
            ref="authenticationInfo"
            :key="tabIndex"
            v-if="authMessage < 200"
          />
          <authPersonalInfo
            ref="authPersonalInfo"
            :key="tabIndex"
            v-if="authMessage >= 200"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import BasicInfo from '../UserInfo/BasicInfo'
import AuthenticationInfo from '../Auth/AuthenticationInfo'
import AuthPersonalInfo from '../Auth/AuthPersonalInfo'
export default {
  components: {
    BasicInfo,
    AuthenticationInfo,
    AuthPersonalInfo,
  },
  data() {
    return {
      tabIndex: 1,
      loading: false
    }
  },
  computed: {
    ...mapState({
      authMessage: (state) => state.auth.authMessage,
      authData: (state) => state.auth.authInfo,
    }),
  },
  activated() {
    this.tabIndex = 1
  },
  methods: {
    changeTab(index) {
      this.tabIndex = index
    },
    submit(){
      this.loading = true
      this.$refs.basicInfo.submit()
    },
    setLoading(){
      this.loading = false
    }
  },
}
</script>
<style lang="less" scoped>
.content {
  .nav-box{
     background: #fff;
     border-bottom: 1px solid #e5e5e5;

     .nav-box-right{
       color: #f5222d;
       padding: 0 20px;
     }

  }
  .tab-nav {
    .horn-title-style {
      cursor: pointer;
      width: 160px;
      height: 55px;
      line-height: 55px;
      position: relative;
      font-size: 22px;
      font-weight: 400;
      color: #333;
      text-align: center;

      &::before {
        content: '';
        width: 0;
        height: 0;
        border: 10px solid transparent;
        top: 100%;
        left: 50%;
        position: absolute;
        margin-left: -12px;
      }
    }

    .active {
      background-color: #1285d6;
      color: #fff;
      &::before {
        border-top-color: rgb(18, 133, 214);
      }
    }
  }
}
</style>
